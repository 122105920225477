import { useState } from "react";

import { Switch } from "@headlessui/react";
import classNames from "classnames";

import { useThemeStore } from "hooks/useThemeStore";

import { SvgCross, SvgInfo, SvgParents } from "assets/svg";

export default function ParentalModeSwitch({ variant = "normal" }: { variant: "normal" | "small" }) {
  const parentalMode = useThemeStore(state => state.parentalMode);
  const toggleParentalMode = useThemeStore(state => state.toggleParentalMode);
  const theme = useThemeStore(state => state.theme);
  const [showTooltip, setShowTooltip] = useState(true);

  return (
    <div className="flex items-center">
      {variant !== "normal" && !showTooltip && (
        <button
          className="group mr-2 rounded-full bg-white p-2.5 shadow"
          onClick={() => setShowTooltip(true)}
        >
          <SvgInfo
            className={classNames(
              "h-7 w-7",
              theme === "blue" && "group-hover:text-blue",
              theme === "green" && "group-hover:text-green",
              theme === "red" && "group-hover:text-red",
              theme === "orange" && "group-hover:text-orange",
            )}
          />
        </button>
      )}

      <div
        className={classNames(
          "relative flex items-center justify-between rounded-full bg-white font-heading text-xs shadow-[0_2px_9px_rgba(0,0,0,0.08)] transition-all",
          variant === "normal" ? "w-[185px] px-5 py-2" : "p-2",
        )}
        aria-label="Volwassenmodus"
      >
        {variant !== "normal" && showTooltip && (
          <div className="absolute bottom-0 right-1/2 mb-14 w-screen max-w-[250px] translate-x-1/2 rounded-lg border bg-white p-4 shadow-lg md:right-0 md:translate-x-0">
            <button className="absolute right-0 top-0 p-5" onClick={() => setShowTooltip(false)}>
              <SvgCross
                className={classNames("w-3", theme === "red" && "group-hover:text-red")}
                aria-hidden="true"
              />
              <span className="sr-only">Melding sluiten</span>
            </button>

            <p className="font-sans text-sm">
              Ouders, verzorgers en leerkrachten kunnen deze switch gebruiken om de <b>volwassenmodus</b> aan
              te zetten. Hierdoor kun je aanvullende informatie lezen.
            </p>
          </div>
        )}

        {variant === "normal" && (
          <span
            className={classNames(
              "before:invisible before:block before:h-0 before:overflow-hidden before:font-extrabold before:content-[attr(title)]",
              !parentalMode && "font-extrabold",
            )}
            aria-hidden="true"
          >
            Uit
          </span>
        )}
        <Switch
          checked={parentalMode}
          onChange={toggleParentalMode}
          className="relative inline-flex h-9 w-16 items-center rounded-full bg-grey-200 outline-none focus:ring focus-visible:ring"
        >
          <span className="sr-only">
            {parentalMode ? "Volwassenmodus uitschakelen" : "Volwassenmodus inschakelen"}
          </span>
          <span
            className={classNames(
              "flex h-7 w-7 transform items-center justify-center rounded-full transition",
              parentalMode ? "translate-x-8 bg-green" : "translate-x-1 bg-darkblue",
            )}
          >
            <SvgParents className="h-4 text-white" aria-hidden="true" />
          </span>
        </Switch>
        {variant === "normal" && (
          <span
            className={classNames(
              "before:invisible before:block before:h-0 before:overflow-hidden before:font-extrabold before:content-[attr(title)]",
              parentalMode && "font-extrabold",
            )}
            aria-hidden="true"
          >
            Aan
          </span>
        )}
      </div>
    </div>
  );
}
