import { SvgBlob } from "assets/svg";

export default function Blobs({ hasSafeArea = false }: { hasSafeArea?: boolean }) {
  if (hasSafeArea) {
    return (
      <div className="absolute left-0 top-0 -z-10 h-full w-full overflow-hidden">
        <SvgBlob
          opacity={1}
          className="absolute -left-[280px] h-[400px] w-[400px] text-[#FDD7D9] md:-left-[200px] lg:left-auto lg:right-[80vw] lg:h-[600px] lg:w-[600px]"
          aria-hidden="true"
        />

        <SvgBlob
          opacity={1}
          className="absolute -right-[450px] h-[600px] w-[600px] text-[#DDEFD4] md:-right-[350px] md:top-1/2 md:-translate-y-1/2 md:transform lg:left-[75vw] lg:left-[80vw] lg:right-auto lg:h-[800px] lg:w-[800px]"
          aria-hidden="true"
        />

        <SvgBlob
          opacity={1}
          className="absolute -left-[250px] bottom-0 h-[400px] w-[400px] text-[#FDECCE] md:-left-[200px] lg:left-auto lg:right-[65vw] lg:h-[600px] lg:w-[600px]"
          aria-hidden="true"
        />

        <SvgBlob
          opacity={1}
          className="absolute -right-[300px] bottom-0 h-[400px] w-[400px] text-[#D4E0FE] md:-right-[200px] lg:left-[65vw] lg:right-auto lg:h-[600px] lg:w-[600px]"
          aria-hidden="true"
        />
      </div>
    );
  }

  return (
    <div className="absolute left-0 top-0 -z-10 hidden h-[calc(100%+110px)] w-full overflow-hidden md:block">
      <SvgBlob
        opacity={1}
        className="absolute -top-[200px] right-[60vw] h-[600px] w-[600px] text-[#FDD7D9]"
        aria-hidden="true"
      />

      <SvgBlob
        opacity={1}
        className="absolute left-[75vw] h-[600px] w-[600px] text-[#DDEFD4]"
        aria-hidden="true"
      />

      <SvgBlob
        opacity={1}
        className="absolute -bottom-[100px] left-[55vw] h-[650px] w-[650px] text-[#FDECCE]"
        aria-hidden="true"
      />

      <SvgBlob
        opacity={1}
        className="absolute -bottom-[100px] right-[80vw] h-[800px] w-[800px] text-[#D4E0FE]"
        aria-hidden="true"
      />
    </div>
  );
}
