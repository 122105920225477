import type { ThemeType } from "hooks/useThemeStore";

export default function getAgeCategoryForColor(color: ThemeType) {
  switch (color) {
    case "blue":
      return "4-7";
    case "green":
      return "7-10";
    case "red":
      return "10-12";
    case "orange":
      return "12-18";
    default:
      return "4-7";
  }
}
