import type { GetStaticPropsContext } from "next";

import type { PageProps } from "lib/getPageProps";
import getPageProps from "lib/getPageProps";

import { getLandingPage } from "cms/requests";
import type { LandingRecord } from "cms/types";

import AgeSelector from "components/AgeSelector";
import Blobs from "components/Blobs";
import Layout from "components/Layout";
import PageMeta from "components/PageMeta";

export const getStaticProps = getPageProps<GetStaticPropsContext>(async () => {
  const landing = await getLandingPage();

  return {
    props: {
      landing,
    },
    revalidate: 600,
  };
});

export default function Home({ landing, ...pageProps }: { landing: LandingRecord } & PageProps) {
  return (
    <>
      <PageMeta siteMeta={pageProps.siteMeta} />

      <Layout {...pageProps}>
        <AgeSelector data={landing} />
        <Blobs />
      </Layout>
    </>
  );
}
