import { useRouter } from "next/router";

import classNames from "classnames";

import getAgeCategoryForColor from "lib/getAgeCategoryForColor";

import type { LandingRecord } from "cms/types";

import { useThemeStore } from "hooks/useThemeStore";

import ParentalModeSwitch from "components/ParentalModeSwitch";

import { SvgGhostSparks } from "assets/svg";

function AgeBlock({
  age,
  variant,
  onClick,
}: {
  age: string;
  variant: "red" | "green" | "blue" | "orange";
  onClick: () => void;
}) {
  return (
    <button
      className={classNames(
        "age-selector__age-block pointer-events-auto relative flex aspect-square w-full appearance-none items-center justify-center rounded-xl shadow-[0_2px_15px_rgba(0,0,0,0.1)] outline-none transition-opacity focus:ring focus:ring-offset-2 focus-visible:ring focus-visible:ring-offset-2",
        variant === "red" && "bg-red focus:ring-red focus-visible:ring-red",
        variant === "green" && "bg-green focus:ring-green focus-visible:ring-green",
        variant === "blue" && "bg-blue focus:ring-blue focus-visible:ring-blue",
        variant === "orange" && "bg-orange focus:ring-orange focus-visible:ring-orange",
      )}
      onClick={onClick}
    >
      <SvgGhostSparks className="w-full" aria-hidden="true" id={age} />

      <div className="absolute -bottom-5 left-1/2 block w-full max-w-[110px] -translate-x-1/2 transform rounded-md bg-white p-1 font-heading text-3xl font-extrabold shadow-[0_2px_15px_rgba(0,0,0,0.1)]">
        {age}
      </div>
    </button>
  );
}

export default function AgeSelector({ data }: { data: LandingRecord }) {
  const setTheme = useThemeStore(state => state.setTheme);
  const { push } = useRouter();

  return (
    <>
      <div className="relative">
        <div className="mx-auto w-full max-w-4xl px-5 md:px-10">
          <h1 className="mb-2.5 font-heading text-3xl font-extrabold md:mb-3 md:text-30 md:leading-loose">
            {data.title}
          </h1>

          <p className="text-sm md:text-base md:leading-loose">{data.intro}</p>

          <span className="mb-3 mt-4 block font-heading text-xl md:mb-8 md:mt-16 md:text-2xl">
            Hoe oud ben je?
          </span>

          <div className="age-selector__container pointer-events-none grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-4 md:gap-x-6">
            <AgeBlock
              age="4-7"
              variant="blue"
              onClick={async () => {
                setTheme("blue");
                await push(`/${getAgeCategoryForColor("blue")}`);
              }}
            />
            <AgeBlock
              age="7-10"
              variant="green"
              onClick={async () => {
                setTheme("green");
                await push(`/${getAgeCategoryForColor("green")}`);
              }}
            />
            <AgeBlock
              age="10-12"
              variant="red"
              onClick={async () => {
                setTheme("red");
                await push(`/${getAgeCategoryForColor("red")}`);
              }}
            />
            <AgeBlock
              age="12-18"
              variant="orange"
              onClick={async () => {
                setTheme("orange");
                await push(`/${getAgeCategoryForColor("orange")}`);
              }}
            />
          </div>

          <p className="mt-8 text-sm md:mx-auto md:mt-16 md:max-w-[540px] md:text-center">
            Ouders, verzorgers en leerkrachten kunnen deze switch gebruiken om de <b>volwassenmodus</b> aan te
            zetten. Hierdoor kun je aanvullende informatie lezen.
          </p>

          <div className="mt-3 flex w-full justify-center md:mt-8">
            <ParentalModeSwitch variant="normal" />
          </div>
        </div>
      </div>
    </>
  );
}
